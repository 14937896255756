<template>
  <div>
    <b-card title="Cari Talent Sesuai Bisnismu 🚀">
      <b-card-text>Waktu adalah uang.</b-card-text>
      <b-card-text>Dapatkan talent terbaik dan mulai jalankan bisnismu!.</b-card-text>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
  },
}
</script>

<style>

</style>
